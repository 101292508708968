<template>
  <el-dialog
     :append-to-body="true"
     :close-on-click-modal="false"
     :visible.sync="dialog"
     :title="dialogTitle"
      width="700px"
     class="add-withgold-rule"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="140px"
    >
      <el-form-item
        prop="actName"
        label="赠金规则名称"
      >
        <el-input
          maxlength="20"
          v-model="form.actName"
        />
      </el-form-item>
      <el-row :gutter="30">
        <el-col :span="12">
          <el-form-item
            prop="withgoldType"
            label="赠金类型"
          >
            <el-select
              v-model="form.withgoldType"
              placeholder=""
            >
              <el-option
                v-for="item in withgoldTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="rebateType" label="返佣类型">
            <el-select
                    :disabled="rebateTypeDisable"
                    v-model="form.rebateType"
                    placeholder="">
              <el-option
                v-for="item in rebateTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :span="12">
          <el-form-item prop="standardsType" label="达标类型">
            <el-select
              :disabled="standardsTypeDisabled"
              v-model="form.standardsType"
              placeholder=""
            >
              <el-option
                v-for="item in standardsTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="amountTomark"
            label="达标金额($)"
          >
            <el-input
              :disabled="amountTomarkDisabledFlag"
              v-model="form.amountTomark"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :span="12">
          <el-form-item prop="tomarkWithgold" label="赠金方式">
            <el-select
              :disabled="tomarkWithgoldDisableFlag"
              v-model="form.tomarkWithgold"
              placeholder="">
              <el-option
                v-for="item in tomarkWithgoldOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="giveNumber" label="赠金数量($)">
            <el-input v-model="form.giveNumber"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :span="12">
          <el-form-item :label="coefficientLabel">
            <p v-if="coefficientDescFlag" class="p-ele">完成手数=赠金数量/系数</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="coefficient" label="">
            <el-input
              :disabled="coefficientDisabled"
              :placeholder="coefficientPlaceHolder"
              v-model="form.coefficient"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :span="12">
          <el-form-item
            prop="limitTimes"
            label="次数限制:"
          >
            <el-select
              :disabled="limitTimesDisabled"
              v-model="form.limitTimes"
              clearable
              placeholder="请选择状态"
              class="filter-item" style="width: 130px">
              <el-option
                v-for="item in limitOptions"
                :key="item.key"
                :label="item.display_name"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="limitTimeNumber" v-if="form.limitTimes == 2" label="">
            <el-input
              :disabled="limitTimeNumberDisabledFlag"
              placeholder="请输入限制次数"
              v-model="form.limitTimeNumber"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :span="12">
          <el-form-item prop="withgold" label="赠金限制($):">
            <el-input v-model="form.withgold"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="completeDay" label="完成时间(天)">
            <el-input placeholder="" :disabled="form.withgoldType == 2" v-model="form.completeDay"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
              v-if="form.withgoldType == 3"
              label="活动区域">
        <el-select
                v-model="form.country"
                multiple
                placeholder=""
                class="country-select"
                @change="changeSelect"
                filterable
        >
          <el-option
                  value="-1"
          >
            <el-checkbox
                    @change='selectAll'
                    v-model="countryAllChecked"
            >全选</el-checkbox>
          </el-option>
          <el-option
                  v-for="item in countryList"
                  :key="item.value"
                  :label="item.description"
                  :value="item.description"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="exclude-symbol"
        label="排除的交易种类">
        <el-select
          v-model="form.excludeSymbol"
          :disabled="form.withgoldType == 2"
          multiple
          placeholder=""
        >
          <el-option
            v-for="item in excludeSymbolOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动时间:">
        <el-date-picker
          style="width: 150px;"
          v-model="form.startTime"
          type="date"
          placeholder="开始日期">
        </el-date-picker>
        至
        <el-date-picker
          style="width: 150px;"
          v-model="form.endTime"
          type="date"
          placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="priority" label="优先级">
        <el-input placeholder="数值越高,优先级越高" v-model="form.priority"/>
      </el-form-item>
      <el-form-item label="规则描述">
        <el-input
          type="textarea"
          placeholder=""
          :autosize="{ minRows: 2}"
          v-model="form.ruleDescribe"
          maxlength="500"
          show-word-limit
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addWithGoldRule, editWithGoldRule, queryMt4TradeSymbolList, queryEditMt4TradeSymbolList } from '@/api/bussetup/withGold'
import { noZeroInit, validatPositive, twoDecimal } from '@/utils/validate'
import { parseTime } from '@/utils/index';
import { getDictListByType } from '@/api/data.js';
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    goldInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    let self = this;
    var validateAmountTomark = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('达标金额不能为空'));
      }
      setTimeout(() => {
        if(this.form.withgoldType !== 3){
          if (!noZeroInit(value)) {
            callback(new Error('请输入非0正整数'));
          } else {
            callback();
          }
        }else {
          callback();
        }
      }, 1000);
    };
    var validateGiveNumber = (rule, value, callback) => {
      if(self.form.tomarkWithgold == 1){
        callback();
      }else {
        if (!value) {
          return callback(new Error('赠金数量不能为空'));
        }
        setTimeout(() => {
          if (noZeroInit(value) && (Number(value) >= 0 &&  Number(value) <= 100)) {
            callback();
          } else {
            callback(new Error('请输入0-100'));
          }
        }, 1000);
      }
    };
    var validateCoefficient = (rule, value, callback) => {
      if(self.form.withgoldType == 2){
        callback();
      }else {
        if (value === '') {
          return callback(new Error('系数值不能为空'));
        }
        setTimeout(() => {
          if(value === 0){
            callback(new Error('请输入大于0的数值'));
          }
          if (twoDecimal(value)) {
            callback();
          } else {
            callback(new Error('最多两位小数'));
          }
        }, 1000);
      }
    };
    var validateLimitTimeNumber = (rule, value, callback) => {
      if(self.form.limitTimes ==1){
        callback();
      }else {
        if (!value) {
          return callback(new Error('限制次数不能为空'));
        }
        setTimeout(() => {
          if (noZeroInit(value)) {
            callback();
          } else {
            callback(new Error('请输入大于0的正整数'));
          }
        }, 1000);
      }
    };
    var validateWithgold = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('赠金限制不能为空'));
      }
      setTimeout(() => {
        if (noZeroInit(value)) {
          // 赠金限制因大于赠金金额
          if(self.form.tomarkWithgold == 1){
            // 达标类型为固定时
            if(+value < +self.form.giveNumber){
              callback(new Error('赠金限制必须大于等于赠金金额'));
            }else {
              callback();
            }
          }else {
            if(+value < +(self.form.amountTomark * (self.form.giveNumber / 100))){
              callback(new Error('赠金限制必须大于等于赠金金额'));
            }else {
              callback();
            }
          }
          callback();
        } else {
          callback(new Error('请输入大于0的正整数'));
        }
      }, 1000);
    };
    var validateCompleteDay = (rule, value, callback) => {
      if(self.form.withgoldType == 2){
        callback();
      }else {
        if (!value) {
          return callback(new Error('完成时间(天)不能为空'));
        }
        setTimeout(() => {
          if (noZeroInit(value)) {
            callback();
          } else {
            callback(new Error('请输入大于0的正整数'));
          }
        }, 1000);
      }
    };

    var validatePriority = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('优先级不能为空'));
      }
      setTimeout(() => {
        if (noZeroInit(value)) {
          callback();
        } else {
          callback(new Error('请输入大于0的正整数'));
        }
      }, 1000);
    };

    return {
      loading: false,
      dialog: false,
      form: {
        id: '',
        actName: '',
        withgoldType: '',
        rebateType: '',
        standardsType:'', //达标类型
        amountTomark: '',
        tomarkWithgold: '',
        giveNumber: '',
        coefficient: '',
        limitTimes: '',
        limitTimeNumber: '',
        withgold: '',
        completeDay: '',
        excludeSymbol: [],
        startTime: '',
        endTime: '',
        priority: '',
        ruleDescribe: '',
        country: [],
      },
      rules:{
        actName: [
          { required: true, message: '请输入规则名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符(10个汉字)', trigger: 'blur' }
        ],
        withgoldType: [
          { required: true, message: '请选择赠金类型', trigger: 'change' }
        ],
        rebateType: [
          { required: true, message: '请选择返佣类型', trigger: 'change' }
        ],
        standardsType: [
          { required: true, message: '请选择达标类型', trigger: 'change' }
        ],
        amountTomark: [
          { required: true, message: '请输入达标金额($)', trigger: 'blur' },
          { validator: validateAmountTomark, trigger: 'blur' }
        ],
        tomarkWithgold: [
          { required: true, message: '请选择达标赠金', trigger: 'change' }
        ],
        giveNumber: [
          { required: true, message: '请输入赠送数量', trigger: 'blur' },
          { validator: validateGiveNumber, trigger: 'blur' }
        ],
        coefficient: [
          { validator: validateCoefficient, trigger: 'blur' }
        ],
        limitTimes: [
          { required: true, message: '请选择达标赠金', trigger: 'change' }
        ],
        limitTimeNumber: [
          { required: true, message: '请输入限制次数', trigger: 'blur' },
          { validator: validateLimitTimeNumber, trigger: 'blur' }
        ],
        withgold: [
          { required: true, message: '请输入赠金限制($)', trigger: 'blur' },
          { validator: validateWithgold, trigger: 'blur' }
        ],
        completeDay: [
          { validator: validateCompleteDay, trigger: 'blur' }
        ],
        /*activityTime:[
          { required: true, message: '请输入开始日期和结束日期', trigger: 'blur' },
          { validator: validateStartTimeAndEndTime, trigger: 'change' }
        ],*/
        priority: [
          { required: true, message: '请输入优先级', trigger: 'blur' },
          { validator: validatePriority, trigger: 'blur' }
        ],
      },
      limitOptions:[
        {
          display_name: '不限',
          key: 1,
        },
        {
          display_name: '限制',
          key: 2,
        }
      ],
      withgoldTypeOptions:[
        {
          label: '信用额',
          value: 1,
        },
        {
          label: '余额',
          value: 2,
        },
        {
          label: '开户赠余额',
          value: 3,
        }
      ],
      rebateTypeOptions:[
        {
          label: '全额返佣',
          value: 1,
        },
        {
          label: '按比例返佣',
          value: 2,
        }
      ],
      tomarkWithgoldOptions:[
        {
          label: '固定',
          value: 1,
        },
        {
          label: '比例',
          value: 2,
        }
      ],
      excludeSymbolOptions: [],
      countryList: [],
      countryAllChecked: false,
    }
  },
  created(){
    this.getSymbolLis();
    this.getDictListByType();
  },
  watch:{
    isAdd(newValue,oldValue){
    },
    'form.withgoldType'(newValue,oldValue){
      if(newValue == 2){
        this.form.rebateType = 1;
        this.form.excludeSymbol = [];
        this.form.completeDay = '';
        this.form.coefficient = '';
      }
    },
    form: {
      handler(newValue) {
        if(newValue.withgoldType == 3){
          this.form.tomarkWithgold = 1;
          this.form.amountTomark = 0;
          this.form.limitTimes = 2;
          this.form.limitTimeNumber = 1;
          this.form.standardsType = 1;
        }
      },
      deep: true
    },
    dialog(newValue,oldValue){
      if(!newValue){
        this.resetForm();
      }
      if(this.isAdd){
        this.clearForm();
      }else {
        if(this.goldInfo){
          this.getNeedEditSymbolLis(this.goldInfo.id);
          this.editRulerAssign(this.goldInfo);
        }
      }
    },
  },
  computed:{
    dialogTitle(){
      return this.isAdd ? '添加赠金规则' : '编辑赠金规则'
    },
    rebateTypeDisable(){
      if(this.form.withgoldType == 2){
        return true;
      }else {
        return false;
      }
    },
    coefficientDisabled(){
      return this.form.withgoldType == 2 ? true : false;
    },
    amountTomarkDisabledFlag(){
      if(this.form.withgoldType == 3){
        return true;
      }else {
        return false;
      }
    },
    tomarkWithgoldDisableFlag(){
      return this.form.withgoldType == 3;
    },
    coefficientPlaceHolder(){
      if(this.form.withgoldType != 3){
        return '请输入大于0的系数值';
      }else {
        return '请输入大于0的手数';
      }
    },
    coefficientLabel(){
      if(this.form.withgoldType !=3){
        return '达标完成手数系数';
      }else {
        return '达标完成手数';
      }
    },
    coefficientDescFlag(){
      if(this.form.withgoldType !=3){
        return true;
      }else {
        return false;
      }
    },
    limitTimeNumberDisabledFlag(){
      if(this.form.withgoldType == 3){
        return true;
      }else {
        return false
      }
    },
    limitTimesDisabled(){
      if(this.form.withgoldType == 3){
        return true;
      }else {
        return false
      }
    },
    standardsTypeOptions(){
      let normalArr = [
        {
          label: '首次入金',
          value: 1,
        },
        {
          label: '单次入金',
          value: 2,
        }
      ];
      if(this.form.withgoldType == 3){
        normalArr.map((item,index) => {
          if(index == 0){
            item.label = '首次操作';
          }
        })
      }
      return normalArr;
    },
    standardsTypeDisabled(){
      if(this.form.withgoldType == 3){
        return true;
      }else {
        return false;
      }
    }
  },
  methods: {
    parseTime,
    selectAll() {
      this.form.country = []
      if (this.countryAllChecked) {
        this.countryList.map((item) => {
          this.form.country.push(item.description);
        })
      } else {
        this.form.country = []
      }
    },
    changeSelect(val) {
      if (val.length === this.countryList.length) {
        this.countryAllChecked = true
      } else {
        this.countryAllChecked = false
      }
      this.$forceUpdate();
    },
    getDictListByType() {
      getDictListByType({type: 'phoneList'}).then((res) => {
        if (res[0].type == 'phoneList') {
          this.countryList = res;
        }
      });
    },
    editRulerAssign(needEditGoldInfo){
      /*id: '',
        actName: '',
        withgoldType: '',
        rebateType: '',
        standardsType:'', //达标类型
        amountTomark: '',
        tomarkWithgold: '',
        giveNumber: '',
        coefficient: '',
        limitTimes: '',
        limitTimeNumber: '',
        withgold: '',
        completeDay: '',
        excludeSymbol: [],
        startTime: '',
        endTime: '',
        priority: '',
        ruleDescribe: '',*/
      this.form.id = needEditGoldInfo.id;
      this.form.actName = needEditGoldInfo.ruleName;
      this.form.withgoldType = needEditGoldInfo.giveType;
      this.form.rebateType = needEditGoldInfo.returnType;
      this.form.standardsType = needEditGoldInfo.successType;
      this.form.amountTomark = needEditGoldInfo.successMoney;
      this.form.tomarkWithgold = needEditGoldInfo.giveMoneyType;
      this.form.giveNumber = needEditGoldInfo.giveMoney;
      this.form.coefficient = needEditGoldInfo.coefficient;
      this.form.withgold = needEditGoldInfo.maxGiveMoney;
      this.form.startTime = needEditGoldInfo.startTime;
      this.form.endTime = needEditGoldInfo.endTime;
      this.form.priority = needEditGoldInfo.priority;
      this.form.ruleDescribe = needEditGoldInfo.ruleDescribe;
      this.form.country = JSON.parse(JSON.stringify(needEditGoldInfo.country));

      if(needEditGoldInfo.giveCount == 0){
        this.form.limitTimes = 1;
      }else {
        this.form.limitTimes = 2;
        this.form.limitTimeNumber = needEditGoldInfo.giveCount;
      }
        // 完成天数
      this.form.completeDay = needEditGoldInfo.completionTime;

    },
    getNeedEditSymbolLis(ruleId){
      queryEditMt4TradeSymbolList({ruleId: ruleId}).then((res) => {
        let editIds = [];
        if(res.length){
          for (let i = 0; i < res.length; i++) {
            editIds.push(res[i].id);
          }
        }
        this.form.excludeSymbol = editIds
      })
    },
    getSymbolLis(){
      queryMt4TradeSymbolList().then((res) => {
        this.excludeSymbolOptions = res.content;
      })
    },
    cancel() {
      this.dialog = false
    },
    handleParam(){
      let params = {
        rule:{
          ruleName: this.form.actName,
          giveType:this.form.withgoldType,
          returnType:this.form.rebateType,
          successType:this.form.standardsType,
          successMoney:this.form.amountTomark,
          giveMoneyType:this.form.tomarkWithgold,
          giveMoney:this.form.giveNumber,

          coefficient:this.form.coefficient,
          // 赠金限制
          maxGiveMoney:this.form.withgold,

          startTime:this.parseTime(this.form.startTime),
          endTime:this.parseTime(this.form.endTime).slice(0,11) + '23:59:59',
          priority: this.form.priority,
          ruleDescribe:this.form.ruleDescribe,
        },
      };
      if(this.form.country && this.form.country.length){
        params.rule.country = this.form.country.join();
      }
      if(!this.idAdd){
        params.rule.id = this.form.id;
      }
      if(this.form.limitTimes == 1){
        params.rule.giveCount = 0;
      }else {
        params.rule.giveCount = this.form.limitTimeNumber;
      }
      // 赠金类型为信用额时,完成天数不限制
      if(this.form.withgoldType !== 2){
        // 完成天数
        params.rule.completionTime = this.form.completeDay;
      }
      // 赠金类型为信用额,才可以选择排除的交易种类
      if(this.form.withgoldType ==1){
        if(this.form.excludeSymbol.length){
          params.symbolIds = this.form.excludeSymbol;
        }
      }
      return params;
    },
    doSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!this.form.startTime ) {
            return this.$message('开始日期不能为空');
          }else if(!this.form.endTime){
            return this.$message('结束日期不能为空');
          }
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else this.doEdit()
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    doAdd() {
      let params = this.handleParam();
      addWithGoldRule(params).then(res => {
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false;
        this.dialog = false;
        this.$parent.init()
      }).catch(err => {
        this.loading = false
      })
    },
    doEdit() {
      let params = this.handleParam();
      editWithGoldRule(params).then(res => {
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.dialog = false;
        this.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {

      this.$refs.form.resetFields();
    },
    clearForm() {
      let emptForm = {
        id: '',
        actName: '',
        withgoldType: '',
        rebateType: '',
        standardsType:'', //达标类型
        amountTomark: '',
        tomarkWithgold: '',
        giveNumber: '',
        coefficient: '',
        limitTimes: '',
        limitTimeNumber: '',
        withgold: '',
        completeDay: '',
        excludeSymbol: '',
        startTime: '',
        endTime: '',
        priority: '',
        ruleDescribe: '',
      };
      this.form = {...emptForm};

    }
  }
}
</script>
<style lang="less">
  .add-withgold-rule {
    .exclude-symbol {
       .el-select {
         width: 100%;
       }
    }
  }
  .country-select {
    width: 100%;
  }
</style>
<style scoped>
.p-ele {
  margin: 0;
}
</style>
