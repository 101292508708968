<template>
  <div class="app-container">
    <div class="grants-header">
      <el-button
              type="primary"
              @click="addNew"
              icon="el-icon-plus"
              v-if="checkPermission(['ADMIN','TCustBonusRule_CREATE'])"
      >添加赠金活动</el-button>
    </div>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="编号" width="60"/>
      <el-table-column prop="ruleName" label="规则名称">
      </el-table-column>
      <el-table-column prop="successType" label="达标类型">
        <template slot-scope="scope">
          <span>{{getSuccessType(scope.row.successType)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="successMoney" label="达标金额"/>
      <el-table-column prop="giveMoneyType" label="赠金方式">
        <template slot-scope="scope">
          <span>{{getMoneyType(scope.row.giveMoneyType)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="giveMoney" label="赠送数量"/>
      <el-table-column prop="giveType" label="赠金类型">
        <template slot-scope="scope">
          <span>{{getType(scope.row.giveType)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="returnType" label="返佣类型">
        <template slot-scope="scope">
          <span>{{getReturnType(scope.row.returnType)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="completionTime" label="完成天数"/>
      <el-table-column label="活动时间">
        <template slot-scope="scope">
          <span>{{getDuration(scope.row.startTime,scope.row.endTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row.status)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="priority" label="优先级"/>
      <el-table-column label="操作" width="280px" align="center">
        <template slot-scope="scope">

          <el-button
                  class="margin-right5"
                  type="success"
            slot="reference"
            v-if="checkPermission(['ADMIN','TCustBonusRule_SELECT'])"
            round
            @click="doHandle(scope.row)"
            size="mini"
          >查看</el-button>
          <el-button
                  class="margin-right5"
                  type="danger"
                  v-if="checkPermission(['ADMIN','TCustBonusRule_EDIT']) && getEditBtnFlag(scope.row.status)"
                  round
                  @click="editHandle(scope.row)"
                  size="mini"
          >编辑</el-button>
          <el-popover
            v-if="checkPermission(['ADMIN']) && getStartShow(scope.row.status)"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定启用本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="enabledHandle(scope.row.id)">确定</el-button>
            </div>
            <el-button
                    class="margin-right5"
                    type="danger"
              slot="reference"
              round
              size="mini"
            >启用</el-button>
          </el-popover>
          <el-popover
            v-if="checkPermission(['ADMIN']) && getForbiddenFlag(scope.row.status)"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定禁用本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="forbiddenHandle(scope.row.id)">确定</el-button>
            </div>
            <el-button
              class="margin-right5"
              type="danger"
              slot="reference"
              round
              size="mini"
            >禁用</el-button>
          </el-popover>
          <el-button
                  class="margin-right5"
                  type="danger"
            size="mini"
            v-if="checkPermission(['ADMIN','TCustBonusRule_DELETE']) && getDelBtnFlag(scope.row.status)"
            round
            @click="subDelete(scope.row.id)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <grants-dialog
      ref="grantsdialog"
      :is-add="isAdd"
      :gold-info="currentGoldInfo"
    ></grants-dialog>
    <detail-dialog
      ref="detaildialog"
      :gold-info="detailsInfo"
    ></detail-dialog>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import grantsDialog from '@/components/bussetup/grants/form'
import detailDialog from '@/components/bussetup/grants/detail'
import { parseGroup } from '@/views/pt/ib/utils/index'
import { deleteWithGoldRule, startWithGoldRule } from '@/api/bussetup/withGold'
export default {
  name:'grants',
  components: { grantsDialog,detailDialog },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      isAdd: true,
      /*data:[
        {
          id: 1,
          actName: '首次入金奖励',
          type: '首次入金',
          amountTomark: '10000',
          giveNumber: '100',
          withgoldType: '信用额',
          rebateType: '全额返佣',
          completeDay: '30',
          dutringDate: '2020.03.25--2020.04-23',
          status: '1'
        }
      ]*/
      currentGoldInfo: null,
      detailsInfo: null,
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    parseGroup,
    checkPermission,
    beforeInit() {
      this.url = '/crm/tCustBonusRule'
      // const sort = 'id,desc'
      this.params = { page: this.page, size: this.size }
      return true
    },
    editHandle(goldInfo){
      this.currentGoldInfo = goldInfo;
      this.isAdd = false;
      this.$refs.grantsdialog.dialog = true;
    },
    doHandle(goldInfo) {
      this.detailsInfo = goldInfo;
      this.$refs.detaildialog.dialog = true;
    },
    subDelete(id) {
      this.$confirm(`是否要删除本条活动`,'提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //点击确定的操作(调用接口)
        deleteWithGoldRule({id:id}).then(res => {
          this.init()
          this.$notify({
            title: '删除成功',
            type: 'success',
            duration: 1000
          })
        }).catch(err => {

        })
      }).catch(() => {
        //几点取消的提示
      });
    },
    addNew(){
      this.isAdd = true;
      this.$refs.grantsdialog.dialog = true;
    },
    getSuccessType(successType){
      return successType == 1 ? '首次入金 ':  '单次入金';
    },
    getMoneyType(giveMoneyType){
      return giveMoneyType == 1 ? '固定数量  ':  '按比例送';
    },
    getType(giveType){
      return giveType == 1 ? '信用额':  '余额';
    },
    getReturnType(ReturnType){
      return ReturnType == 1 ? '全额返佣 ':  '按比例返佣';
    },
    getDuration(startTime,endTime){
      return startTime + ' -- ' + endTime;
    },
    getStatus(status){
      // 0-未启用 1-已启用 2-已过期 3-禁用
      switch (status) {
        case 1:
          return '已启用';
        case 0:
          return '未启用';
        case 2:
          return '已过期';
        case 3:
          return '已禁用';
      }
    },

    getEditBtnFlag(status){
      return status == 0;
    },
    getStartShow(status){
      if(status !== 0){
        return false;
      }else {
        return  true;
      }
    },
    getForbiddenFlag(status){
      return status == 1 && status !== 3
    },
    getDelBtnFlag(status){
      return status == 0;
    },
    enabledHandle(id){
      this.$confirm(`启用后不可修改规则`,'确认启用', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //点击确定的操作(调用接口)
        //启用接口
        startWithGoldRule({status: 1,id:id }).then(res => {
          this.init()
          this.$notify({
            title: '启用成功',
            type: 'success',
            duration: 1000
          })
        }).catch(err => {

        })
      }).catch(() => {
        //几点取消的提示
      });
    },
    forbiddenHandle(id){
      this.$confirm(`是否要禁用本条活动`,'提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //点击确定的操作(调用接口)
        //启用接口
        startWithGoldRule({status: 3,id:id }).then(res => {
          this.init()
          this.$notify({
            title: '禁用成功',
            type: 'success',
            duration: 1000
          })
        }).catch(err => {

        })
      }).catch(() => {
        //几点取消的提示
      });
    }
  }
}
</script>

<style scoped>

</style>
