<template>
  <el-dialog
     :append-to-body="true"
     :close-on-click-modal="false"
     :visible.sync="dialog"
     title="查看赠金规则"
     width="650px"
     class="add-withgold-rule"
  >
    <el-form
      ref="form"
      :model="form"
      size="small"
      label-width="120px">
      <el-row :gutter="30">
        <el-col :span="12">
          <el-form-item prop="ruleName" label="规则名称：">
            {{form.ruleName}}
          </el-form-item>
        </el-col>
        <el-col :span="12">
           <el-form-item prop="successType" label="达标类型：">
            <span>{{form.successType ==1?'首次入金':form.successType ==2?'单次入金':''}}</span>
          </el-form-item>
        </el-col>
         <el-col :span="12">
           <el-form-item prop="successMoney" label="达标金额：">
             <span>{{form.successMoney}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
           <el-form-item prop="giveMoneyType" label="赠金方式：">
             <span>{{form.giveMoneyType ==1?'固定数量':form.giveMoneyType ==2?'按比例送':''}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
           <el-form-item prop="giveType" label="赠金类型：">
             <span>{{giveType}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
           <el-form-item prop="returnType" label="返佣类型：">
             <span>{{form.returnType ==1?'全额返佣':form.returnType ==2?'按比例返佣':''}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
           <el-form-item prop="completionTime" label="完成天数：">
             <span>{{form.completionTime}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
           <el-form-item v-if="form.giveType != 2" prop="coefficient" :label="getLabel()">
             <span>{{this.form.coefficient}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
           <el-form-item prop="giveCount" label="次数限制：">
             <span v-if="form.giveCount>0">{{form.giveCount}}</span>
             <span v-else>不限</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
           <el-form-item prop="completionTime" label="完成时间：">
             <span>{{form.completionTime}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
           <el-form-item prop="maxGiveMoney" label="赠金限制：">
             <span>{{form.maxGiveMoney}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
           <el-form-item prop="priority" label="优先级：">
             <span>{{form.priority}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
           <el-form-item prop="status" label="状态：">
             <span>{{getStatus(form.status)}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="24">
           <el-form-item prop="successType" label="规则描述:">
             {{form.ruleDescribe}}
          </el-form-item>
        </el-col>
        <el-col
                v-if="form.country && form.country.length"
                :span="24">
          <el-form-item prop="country" label="活动区域:">
            {{form.country && form.country.length && form.country.join(',')}}
          </el-form-item>
        </el-col>
        <el-col :span="24">
           <el-form-item prop="startTime,endTime" label="活动时间：">
             <span>{{getDuration(form.startTime,form.endTime)}}</span>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {queryViewMt4TradeSymbolList,queryMt4TradeSymbolList } from '@/api/bussetup/withGold'
import { parseTime } from '@/utils/index'
export default {
  props: {
    goldInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      form: {
        country: [],
      },
    }
  },
  computed: {
    giveType(){
      switch (this.form.giveType) {
        case 1:
          return '信用额';
        case 2:
          return '余额';
        case 3:
          return '开户赠余额';
      }
    }
  },
  watch:{
    dialog(newValue,oldValue){
      if(newValue) {
        this.getViewLis(this.goldInfo.id);
      }
    },
  },

  methods: {
    parseTime,
    getLabel(){
      return this.form.giveType == 3 ? '达标手数：' : '达标系数：'
    },
    getViewLis(id){
      queryViewMt4TradeSymbolList({id:id}).then((res) => {
        this.form = res;
      })
    },
    cancel() {
      this.dialog = false
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    clearForm() {
      let emptForm = {};
      this.form = {...emptForm};
    },
    getDuration(startTime,endTime){
      return startTime + ' -- ' + endTime;
    },
    getStatus(status){
      // 0-未启用 1-已启用 2-已过期 3-禁用
      switch (status) {
        case 1:
          return '已启用';
        case 0:
          return '未启用';
        case 2:
          return '已过期';
        case 3:
          return '已禁用';
      }
    },
  }
}
</script>
<style lang="less">
  .add-withgold-rule {
    .exclude-symbol {
       .el-select {
         width: 100%;
       }
    }
  }

</style>
<style scoped>

</style>
